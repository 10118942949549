import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, ChangeColor, AvatarOptions, SelectBox } from "..";
import { RootReducer } from "../../redux";
import { fetchDataAvatar } from "../../redux/avatarDataReducer";
import "./EditorAvatar.scss";

export const EditorAvatar = () => {
  const {gender, select_option} = useSelector(({avatar}:RootReducer) => avatar)
  const {loading} = useSelector(({fetchData}:RootReducer) =>fetchData)
  const dispatch = useDispatch()


useEffect(() => {
    dispatch(fetchDataAvatar(gender!))
}, [gender, dispatch])

if(loading){
  return <h1>...loading</h1>
}
  return (
    <div className={"EditorAvatar"}>
      <div className="EditorAvatar__inner">
        <div className="EditorAvatar__left">
          <Avatar />
          {select_option === 'face' ? null : <ChangeColor />}
        </div>
        <div className="EditorAvatar__right">
          <AvatarOptions />
          <div className="EditorAvatar__select-box-wrapper">
            <SelectBox />
            <button className="EditorAvatar__button">Save as PNG</button>
          </div>
        </div>
      </div>
    </div>
  );
};
