import React from 'react'
import './App.scss'
import { useSelector } from 'react-redux'
import { RootReducer } from './redux'
import {SelectGender, EditorAvatar} from './components'

const App = () => {

    const { gender } = useSelector(({ avatar }: RootReducer) => avatar)

    if (!gender) {
        return (
            <div className={'wrapper'}>
                <SelectGender />
            </div>
        )
    }

    return (
        <div className={'wrapper'}>
            <EditorAvatar />
        </div>
    )
}

export default App
