interface IinitialState {
    select_option: 'face' | 'lips' | 'brows' | 'hair' | 'blush' | 'accessories' | 'beard',
    gender: null | "man" | "woman";
    face: {
        link: string,
        color: string,
        x: string,
        y: string,
        width: string,
        height: string
    } | null,
    lips: {
        link: string,
        color: string,
        x: string,
        y: string,
        width: string,
        height: string
    } | null,
    brows: {
        link: string,
        color: string,
        x: string,
        y: string,
        width: string,
        height: string
    } | null,
    hair: {
        link: string,
        color: string,
        x: string,
        y: string,
        width: string,
        height: string
    } | null,
    blush: {
        link: string,
        color: string,
        x: string,
        y: string,
        width: string,
        height: string
    } | null,
    beard: {
        link: string,
        color: string,
        x: string,
        y: string,
        width: string,
        height: string
    } | null,
    accessories: {
        link: string,
        color: string,
        x: string,
        y: string,
        width: string,
        height: string
    } | null,
}
enum avatarType {
    CHANGE_GENDER = "CHANGE_GENDER",
    SELECT_OPTIONS = "SELECT_OPTIONS",
    SELECT_COLOR = "SELECT_COLOR",
    SELECT_ITEM = "SELECT_ITEM",
    FETCH_DATA = 'FETCH_DATA',
    FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS',
    FETCH_DATA_ERROR = 'FETCH_DATA_ERROR',
    CHANGE_COORD = 'CHANGE_COORD'
}

const initialState: IinitialState = {
    gender: null,
    select_option: 'face',
    face: null,
    lips: null,
    brows: null,
    hair: null,
    blush: null,
    beard: null,
    accessories: null,
};

interface changeAvatarGender {
    type: avatarType.CHANGE_GENDER;
    payload: null | "man" | "woman";
}

interface selectAvatarOptions {
    type: avatarType.SELECT_OPTIONS,
    payload: 'face' | 'lips' | 'brows' | 'hair' | 'blush' | 'accessories'
}

interface selectAvatarColor {
    type: avatarType.SELECT_COLOR,
    payload: string
}

interface selectAvatarItem {
    type: avatarType.SELECT_ITEM,
    payload: string
}

interface changeCoordAvatar {
    type: avatarType.CHANGE_COORD,
    payload: { x: string, y: string }
}



type actionType = changeAvatarGender | selectAvatarOptions | selectAvatarColor | selectAvatarItem | changeCoordAvatar

export const avatarReducer = (
    state = initialState,
    action: actionType
): IinitialState => {
    switch (action.type) {
        case avatarType.CHANGE_GENDER:
            return {
                ...state,
                gender: action.payload,
            };
        case avatarType.SELECT_OPTIONS:
            return {
                ...state,
                select_option: action.payload
            }
        case avatarType.SELECT_COLOR:
            return {
                ...state,
                [state.select_option]: {
                    ...state[state.select_option],
                    color: action.payload
                }
            }
        case avatarType.SELECT_ITEM:
            return {
                ...state,
                [state.select_option]: {
                    ...state[state.select_option],
                    link: action.payload
                }
            }
        case avatarType.CHANGE_COORD:
            return {
                ...state,
                [state.select_option]: {
                    ...state[state.select_option],
                    x: action.payload.x,
                    y: action.payload.y
                }
            }
        default:
            return {
                ...state,
            };
    }
};

export const actionChangeAvatar = (gender: null | "man" | "woman"): changeAvatarGender => ({
    type: avatarType.CHANGE_GENDER,
    payload: gender,
});

export const actionSelectOptionsAvatar = (option: 'face' | 'lips' | 'brows' | 'hair' | 'blush' | 'accessories'): selectAvatarOptions => ({
    type: avatarType.SELECT_OPTIONS,
    payload: option
})


export const actionSelectColorAvatar = (color: string) => ({
    type: avatarType.SELECT_COLOR,
    payload: color
})

export const actionSelectItemAvatar = (link: string) => ({
    type: avatarType.SELECT_ITEM,
    payload: link
})

export const actionChangeCoordAvatar = ({ x, y }: { x: number, y: number }) => ({
    type: avatarType.CHANGE_COORD,
    payload: { x, y }
})