import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../redux";
import { actionSelectColorAvatar } from "../../redux/avatarReducer";
import {SliderPicker} from 'react-color'

import "./ChangeColor.scss";

const COLOR = [
  "#FF00C7",
  "#D10838",
  "#83695B",
  "#FF4672",
  "#352F2C",
  "#215FFF",
  "#7E7750",
  "#FF9C27",
  "#A86E2A",
  "#875010",
  "#ffffff",
];
export const ChangeColor = () => {
  const avatar = useSelector(({ avatar }: RootReducer) => avatar);
  const dispatch = useDispatch();
  
  return (
    <div className={"ChangeColor"}>
      <div className="ChangeColor__inner">
        <div className="ChangeColor__items">
          {COLOR.map((item, index) => (
            <div
              key={index + item}
              className={`ChangeColor__item ${avatar[avatar.select_option]?.color === item && 'active'}`}
              style={{ background: item }}
              onClick={() => dispatch(actionSelectColorAvatar(item))}
            ></div>
          ))}
        </div>
      </div>
          <SliderPicker color={avatar[avatar.select_option]?.color} onChange={(color) => dispatch(actionSelectColorAvatar(color.hex))} />
    </div>
  );
};
