import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { avatarReducer as avatar } from "./avatarReducer";
import {avatarDataReducer as fetchData} from './avatarDataReducer'
const rootReducer = combineReducers({ avatar, fetchData });

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export type RootReducer = ReturnType<typeof rootReducer>;
