import React from 'react'
import { useGenerateAvatar } from '../../hooks/useGenerateAvatar'


import './Avatar.scss'

export const Avatar = () => {
    const avatar = useGenerateAvatar()
    return (
        <div className={'Avatar'}>
            <div className="Avatar__inner">
                {avatar}
                <div className="Avatar__cube"></div>
            </div>
        </div>
    )
}

