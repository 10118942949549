import React from 'react'
import { useDispatch } from 'react-redux'
import manSvg from '../../assets/svg/man.svg'
import womanSvg from '../../assets/svg/woman.svg'
import { actionChangeAvatar } from '../../redux/avatarReducer'

import './SelectGender.scss'

export const SelectGender = () => {
    const dispatch = useDispatch()
    
    return (
        <div className={'SelectGender'}>
            <div className="SelectGender__inner">
                <div className="SelectGender__item" onClick={() => dispatch(actionChangeAvatar('woman'))}><img src={womanSvg} alt="woman" /></div>
                <div className="SelectGender__item" onClick={() => dispatch(actionChangeAvatar('man'))}><img style={{fill: 'black'}} src={manSvg} alt="man" /></div>
            </div>
        </div>
    )
}
