import React, { MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../redux";
import parser from "html-react-parser";
import axios from "axios";
import { actionChangeCoordAvatar } from "../redux/avatarReducer";

const URL = process.env.REACT_APP_API_URL;
export const useGenerateAvatar = () => {
    const avatar = useSelector(({ avatar }: RootReducer) => avatar);
    const [face, setFace] = useState("");
    const [lips, setLips] = useState("");
    const [brows, setBrows] = useState("");
    const [hair, setHair] = useState("");
    const [beard, setBeard] = useState("");
    const [blush, setBlush] = useState("");
    const [accessories, setAccessories] = useState("");
    const [{ dx, dy }, setOffset] = useState({ dx: 0, dy: 0 })
    const [{ startX, startY }, setStartOffset] = useState({ startX: 0, startY: 0 })
    const [isDrag, setIsDrag] = useState(false)
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const replaceData = (data: string) => {
        return data.replace(/(width|height|fill|fill-opacity|viewBox)="([^"]*)"/g, "");
    };

    useEffect(() => {
        setLoading(true)
        avatar.face &&
            axios
                .get(`${URL}/${avatar.face!?.link}`)
                .then(({ data }) => {
                    setFace(data)
                    setLoading(false)
                });
    }, [avatar.face!?.link]);
    useEffect(() => {
        setLoading(true)
        avatar.hair &&
            axios.get(`${URL}/${avatar.hair!?.link}`).then(({ data }) => {
                setHair(replaceData(data));
                setLoading(false)
            });
    }, [avatar.hair!?.link]);
    useEffect(() => {
        setLoading(true)
        avatar.lips &&
            axios
                .get(`${URL}/${avatar.lips!?.link}`)
                .then(({ data }) => {
                    setLips(replaceData(data))
                    setLoading(false)
                });
    }, [avatar.lips!?.link]);
    useEffect(() => {
        setLoading(true)
        avatar.brows &&
            axios
                .get(`${URL}/${avatar.brows!?.link}`)
                .then(({ data }) => {
                    setBrows(replaceData(data))
                    setLoading(false)
                });
    }, [avatar.brows!?.link]);
    useEffect(() => {
        setLoading(true)
        avatar.beard &&
            axios
                .get(`${URL}/${avatar.beard!?.link}`)
                .then(({ data }) => {
                    setBeard(replaceData(data))
                    setLoading(false)
                });
    }, [avatar.beard!?.link]);
    useEffect(() => {
        setLoading(true)
        avatar.blush &&
            axios
                .get(`${URL}/${avatar.blush!?.link}`)
                .then(({ data }) => {
                    setBlush(replaceData(data))
                    setLoading(false)
                });
    }, [avatar.blush!?.link]);
    useEffect(() => {
        setLoading(true)
        avatar.accessories &&
            axios
                .get(`${URL}/${avatar.accessories!?.link}`)
                .then(({ data }) => {
                    setAccessories(replaceData(data))
                    setLoading(false)
                });
    }, [avatar.accessories!?.link]);

    useEffect(() => {
        setStartOffset({
            startX: +avatar[avatar.select_option]!?.x,
            startY: +avatar[avatar.select_option]!?.y
        })
        setOffset({ dx: +avatar[avatar.select_option]!?.x || 0, dy: +avatar[avatar.select_option]!?.y || 0 })
    }, [avatar.select_option])


    const startDrag = (e: MouseEvent) => {
        setStartOffset(prev => ({
            ...prev,
            startX: e.screenX - dx,
            startY: e.screenY - dy
        }))
        setIsDrag(true)
    }
    const moveDrag = (e: MouseEvent) => {
        if (isDrag) {
            const newDx = e.screenX - startX
            const newDy = e.screenY - startY
            setOffset({ dx: newDx, dy: newDy })
            dispatch(actionChangeCoordAvatar({ x: dx, y: dy }))
        }
    }
    const endDrag = (e: MouseEvent) => {
        setStartOffset({ startY: 0, startX: 0 })
        setIsDrag(false)
    }




    const svg = (
        <svg
            onMouseDown={startDrag}
            onMouseMove={moveDrag}
            onMouseUp={endDrag}
            width="100%"
            height="100%"
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                cursor: 'grab'
            }}
            viewBox="-100 -100 720 800"
        >
            {loading ? <svg viewBox="-30 0 200 50" xmlns="http://www.w3.org/2000/svg">
                <text fontSize="2em" fill="white">Loading...</text>
            </svg> : <>
                <svg>{parser(face)}</svg>
                <svg
                    y={avatar.brows?.y}
                    x={avatar.brows?.x} fill={avatar.brows?.color}>
                    {parser(brows)}
                </svg>
                <svg
                    y={avatar.lips?.y}
                    x={avatar.lips?.x}
                     fill={avatar.lips?.color}>
                    {parser(lips)}
                </svg>
                <svg
                    
                    y={avatar.beard?.y}
                    x={avatar.beard?.x}
                    fill={avatar.beard?.color}>
                    {parser(beard)}
                </svg>
                <svg
                    
                    style={{ zIndex: 100 }}
                    y={avatar.hair?.y}
                    x={avatar.hair?.x}
                    fill={avatar.hair?.color}
                >
                    {parser(hair)}
                </svg>

                <svg
                    y={avatar.blush?.y}
                    x={avatar.blush?.x} fill={avatar.blush?.color}>
                    {parser(blush)}
                </svg>
                <svg
                    y={avatar.accessories?.y}
                    x={avatar.accessories?.x}
                    opacity="50%"
                    fill={avatar.accessories?.color}
                >
                    {parser(accessories)}
                </svg>


            </>}
        </svg>
    );

    return svg;
};
