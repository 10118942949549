import axios from "axios"
import { Dispatch } from "redux"

interface IinitialState {
    data: any[]
    loading: boolean,
    error: string | null
}
const initialState: IinitialState = {
    data: [],
    loading: true,
    error: null
}

enum avatarType {
    FETCH_DATA = 'FETCH_DATA',
    FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS',
    FETCH_DATA_ERROR = 'FETCH_DATA_ERROR'
}

type actionType = fetchAvatarData | fetchAvatarDataSuccess | fetchAvatarDataError


interface fetchAvatarData {
    type: avatarType.FETCH_DATA
}

interface fetchAvatarDataSuccess {
    type: avatarType.FETCH_DATA_SUCCESS,
    payload: any[]
}

interface fetchAvatarDataError {
    type: avatarType.FETCH_DATA_ERROR,
    payload: string
}


export const avatarDataReducer = (state = initialState, action: actionType): IinitialState => {
    switch (action.type) {
        case avatarType.FETCH_DATA:
            return {
                ...state,
                loading: true,
                error: null
            }
        case avatarType.FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null
            }
        case avatarType.FETCH_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}


export const fetchDataAvatar = (gender: string) => async (dispatch: Dispatch<actionType>) => {
    try {
        dispatch({type: avatarType.FETCH_DATA})
        axios.get(`${process.env.REACT_APP_API_URL}/uploads${gender}`)
        .then(({data}) => dispatch({type: avatarType.FETCH_DATA_SUCCESS, payload: data.children}))
        .catch(({response}) => dispatch({type: avatarType.FETCH_DATA_ERROR, payload: response.data.message}))
    } catch (error) {
        dispatch({type: avatarType.FETCH_DATA_ERROR, payload: error})
    }
}