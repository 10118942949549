import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../redux";
import { actionSelectItemAvatar } from "../../redux/avatarReducer";
import "./SelectBox.scss";


export const SelectBox = () => {
  const avatar = useSelector(({ avatar }: RootReducer) => avatar);
  const { data } = useSelector(({ fetchData }: RootReducer) => fetchData);
  const dispatch = useDispatch()

  const filterData: { path: string; name: string }[] =
    data.filter((item) => item.name === avatar.select_option)[0]!?.children || [];

  return (
    <div className={"SelectBox"}>
      <div className="SelectBox__inner">
        <div className="SelectBox__items">
          {filterData.map((item, index) => {
            return (
              <img
                key={item.path + index}
                className={`SelectBox__item ${avatar[avatar.select_option]?.link === item.path ? 'active' : ''}`}
                onClick={() => dispatch(actionSelectItemAvatar(item.path))}
                src={`${process.env.REACT_APP_API_URL}/${item.path}`}
                alt={`${item.name}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
