import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../redux";
import { actionSelectOptionsAvatar } from "../../redux/avatarReducer";
import "./AvatarOptions.scss";

const AVATAR_OPTIONS_WOMAN = [
  "Face",
  "Lips",
  "Brows",
  "Hair",
  "Blush",
  "Accessories",
];
const AVATAR_OPTIONS_MAN = [
  "Face",
  "Lips",
  "Brows",
  "Hair",
  "Beard",
  "Accessories",
];


export const AvatarOptions = () => {
  const {select_option, gender} = useSelector(({avatar}:RootReducer) => avatar)

  const dispatch = useDispatch()
  return (
    <div className={"AvatarOptions"}>
      <div className="AvatarOptions__inner">
        <div className="AvatarOptions__items">
          {gender === 'man' ? AVATAR_OPTIONS_MAN.map((item, key) => {
            return (
              <div 
              key={key + item} 
              className={`AvatarOptions__item ${item.toLocaleLowerCase() === select_option && 'active'}`}
              //@ts-ignore
              onClick={() => dispatch(actionSelectOptionsAvatar(item.toLocaleLowerCase()))}
              >{item}</div>
            )
          }): AVATAR_OPTIONS_WOMAN.map((item, key) => {
            return (
              <div 
              key={key + item} 
              className={`AvatarOptions__item ${item.toLocaleLowerCase() === select_option && 'active'}`}
              //@ts-ignore
              onClick={() => dispatch(actionSelectOptionsAvatar(item.toLocaleLowerCase()))}
              >{item}</div>
            )
          })}
        </div>
      </div>
    </div>
  );
};
